import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { DatePickerProps } from '../constants';
import { classes } from './LazyMonthlyDatePicker.st.css';

const MonthlyDatePicker = loadable(
  () =>
    import(/* webpackChunkName: "MonthlyDatePicker" */ './MonthlyDatePicker'),
);

export const loadMonthlyDatePicker = () => MonthlyDatePicker.load();

export const LazyMonthlyDatePicker: React.FC<DatePickerProps> = (props) => (
  <div className={classes.monthlyDatePickerWrapper}>
    <MonthlyDatePicker {...props} />
  </div>
);
