import React, { useState, useEffect, FC } from 'react';
import {
  Text,
  Badge,
  BadgePriority as BADGE_PRIORITY,
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
  Divider,
  Dropdown,
  DropdownOptionProps,
  TextButton,
  Accordion,
} from 'wix-ui-tpa/cssVars';
import { MultiselectDropdown } from '@wix/bookings-viewer-ui';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { VideoFillXSmall as CameraSVG } from '@wix/wix-ui-icons-common/on-stage/system';
import { Button } from '../Button/Button';
import { classes, st } from './BookingDetails.st.css';
import { BookingDetailsDataHooks } from './constants';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { BookingPreference } from '../../../../utils/bookingPreferences/bookingPreferences';
import AlertIcon from '../../../../assets/Icons/notificationAlert.svg';
import { BookingDetailsViewModel } from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

export type CollapsibleBookingDetailsProps = BookingDetailsProps & {
  isCollapsed: boolean | undefined;
  title: string;
};

const BookingDetails: React.FC<CollapsibleBookingDetailsProps> = ({
  viewModel,
  isCollapsed,
  title,
}) => {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const { onNext, onElementClicked } = useCalendarActions();

  const {
    preferences,
    ctaViewModel: {
      label: ctaText,
      disabled: disableCTAButton,
      fullWidth: ctaFullWidth,
      loading: ctaLoading,
    },
    alert,
  } = viewModel;

  const bookingPreferences = preferences?.bookingPreferences;

  const isCollapseServiceDetailsEnabled = experiments.enabled(
    'specs.bookings.calendar.collapseServiceDetails',
  );

  const onAccordionItemClick = (id: string, isExpanded: boolean) => {
    onElementClicked(
      WidgetComponents.BOOKING_DETAILS,
      isExpanded ? WidgetElements.EXPAND : WidgetElements.COLLAPSE,
    );
  };

  const shouldShowPreferences = () => {
    return bookingPreferences?.some(
      (bookingPreference: BookingPreference) =>
        bookingPreference.options.length > 1,
    );
  };

  const showAccordion =
    isCollapseServiceDetailsEnabled && isCollapsed !== undefined;

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile })}
    >
      {showAccordion ? (
        <Accordion
          key={`details-${isCollapsed}`}
          className={classes.accordion}
          initiallyExpanded={isCollapsed ? '' : 'details'}
          onItemClick={onAccordionItemClick}
          data-hook={BookingDetailsDataHooks.SERVICE_DETAILS}
        >
          <Accordion.Item id="details" title={title}>
            <TimeSlotDetails viewModel={viewModel} />
          </Accordion.Item>
        </Accordion>
      ) : (
        <TimeSlotDetails viewModel={viewModel} />
      )}
      {shouldShowPreferences() ? (
        <BookingPreferences
          viewModel={viewModel}
          showDivider={!showAccordion}
        />
      ) : null}
      <Alert alert={alert} />
      <Button
        disabled={disableCTAButton}
        data-hook={BookingDetailsDataHooks.BOOK_BUTTON}
        className={classes.bookButton}
        fullWidth={ctaFullWidth}
        loading={ctaLoading}
        onClick={() => onNext()}
      >
        {ctaText}
      </Button>
    </div>
  );
};
export default BookingDetails;

const Alert = ({ alert }: { alert?: string }) => {
  return alert ? (
    <SectionNotification
      type={NOTIFICATION_TYPE.alert}
      data-hook={BookingDetailsDataHooks.ALERT}
      className={classes.alert}
    >
      <SectionNotification.Icon icon={<AlertIcon />} />
      <SectionNotification.Text>{alert}</SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

const TimeSlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    preferences,
    videoConferenceBadgeText,
  } = viewModel;

  const bookingPreferences = preferences?.bookingPreferences;

  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={classes.serviceNameAndDate}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={classes.videoConferenceBadge}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {dateAndTime}
        </Text>
      </div>
      {!!bookingPreferences &&
        bookingPreferences?.map((bookingPreference) => {
          return bookingPreference.options.length === 1 ? (
            <Text
              data-hook={`${BookingDetailsDataHooks.TIME_SLOT_DETAILS}-${bookingPreference.key}`}
              className={classes.commonTextStyle}
              tagName={AccessibilityHtmlTags.Paragraph}
              aria-label={bookingPreference.options[0].ariaLabel}
            >
              {bookingPreference.options[0].value}
            </Text>
          ) : null;
        })}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={classes.commonTextStyle}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {paymentDescription}
      </Text>
    </>
  );
};

type BookingPreferencesProps = BookingDetailsProps & {
  showDivider: boolean;
};

const BookingPreferences: FC<BookingPreferencesProps> = ({
  viewModel,
  showDivider,
}) => {
  const {
    onBookingPreferenceOptionSelected,
    onClearSelectedBookingPreferences,
    onElementClicked,
  } = useCalendarActions();
  const [forceDropdownRender, setForceDropdownRender] = useState(false);
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [isPreselectedIdAllowed, setPreselectedIdAllowed] = useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState<BookingPreference>();
  const { dateAndTime, preferences } = viewModel;
  const { t } = useTranslation();

  useEffect(() => {
    setClearButtonVisibility(false);
    setPreselectedIdAllowed(false);
  }, [dateAndTime]);

  const clearDropdowns = () => {
    setForceDropdownRender((currentForceRender) => !currentForceRender);
    onClearSelectedBookingPreferences();
    setClearButtonVisibility(false);
    setPreselectedIdAllowed(false);
  };

  const onDropdownChange = (
    selectedOptionId: string,
    bookingPreference: BookingPreference,
    numberOfParticipants: number,
    isMultipleChoices: boolean,
  ) => {
    onBookingPreferenceOptionSelected({
      key: bookingPreference.key,
      value: selectedOptionId,
      numberOfParticipants,
      isMultipleChoices,
    });
    setClearButtonVisibility(true);
    setPreselectedIdAllowed(true);
  };

  const onDropdownClick = (bookingPreference: BookingPreference) => {
    onElementClicked(
      WidgetComponents.BOOKING_DETAILS,
      WidgetElements.DROPDOWN,
      bookingPreference.key,
    );
    setSelectedDropDown(bookingPreference);
  };

  const getDropdownOptionsForBookingPreference = (
    bookingPreference: BookingPreference,
  ): DropdownOptionProps[] => {
    const dropdownSubtitle = {
      id: bookingPreference.placeholder,
      value: bookingPreference.placeholder,
      isSelectable: false,
      isSectionTitle: true,
    };

    return [dropdownSubtitle, ...bookingPreference.options];
  };

  const getDropdownComponent = (bookingPreference: BookingPreference) => {
    return bookingPreference.isMultipleChoices ? (
      <MultiselectDropdown
        data-hook={
          BookingDetailsDataHooks.DROPDOWN + '-' + bookingPreference.key
        }
        key={dateAndTime + bookingPreference.key + forceDropdownRender}
        className={st(classes.commonMultiselectDropDownStyle, {
          aboveAll: selectedDropDown === bookingPreference,
        })}
        disabled={bookingPreference.disabled}
        onExpandedChange={() => onDropdownClick(bookingPreference)}
        error={!!bookingPreference.error.message}
        errorMessage={bookingPreference.error.message}
        applyButtonContent={t(
          'app.booking-details.dropdowns.custom-preferences.apply.text',
        )}
        aria-label={bookingPreference.placeholder}
        placeholder={bookingPreference.placeholder}
        note={bookingPreference.note!}
        options={bookingPreference.options.map((option) => ({
          id: option.id!,
          value: option.value!,
          subtitle: option.subtitle!,
          counterOptions: {
            incrementAriaLabel: t(
              'app.booking-details.dropdowns.custom-preferences.increment-aria-label',
              { optionName: option.value },
            ),
            decrementAriaLabel: t(
              'app.booking-details.dropdowns.custom-preferences.decrement-aria-label',
              { optionName: option.value },
            ),
            inputAriaLabel: t(
              'app.booking-details.dropdowns.custom-preferences.input-aria-label',
              { optionName: option.value },
            ),
            max:
              bookingPreference.openSpotsRemained! +
              option.numberOfParticipants!,
            numberOfParticipants: option.numberOfParticipants!,
            onChange: (numberOfParticipants: number) =>
              onDropdownChange(
                option.id!,
                bookingPreference,
                numberOfParticipants,
                true,
              ),
          },
        }))}
      />
    ) : (
      <Dropdown
        initialSelectedId={
          isPreselectedIdAllowed ? bookingPreference.preselectedOptionId : '-1' // WA since ui-tpa does not recognize switching from value to undefined as reset
        }
        error={!!bookingPreference.error.message}
        errorMessage={bookingPreference.error.message}
        data-hook={
          BookingDetailsDataHooks.DROPDOWN + '-' + bookingPreference.key
        }
        className={st(classes.commonDropDownStyle, {
          aboveAll: selectedDropDown === bookingPreference,
        })}
        disabled={bookingPreference.disabled}
        aria-label={bookingPreference.placeholder}
        placeholder={bookingPreference.placeholder}
        options={getDropdownOptionsForBookingPreference(bookingPreference)}
        onExpandedChange={() => onDropdownClick(bookingPreference)}
        key={dateAndTime + bookingPreference.key + forceDropdownRender}
        onChange={(selectedOption) =>
          onDropdownChange(selectedOption.id!, bookingPreference, 1, false)
        }
      />
    );
  };

  if (!preferences) {
    return null;
  }

  const { bookingPreferences, titleText, clearText } = preferences;

  return (
    <div data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}>
      {showDivider && (
        <Divider
          className={classes.divider}
          data-hook={BookingDetailsDataHooks.DIVIDER}
        />
      )}
      <div className={classes.bookingPreferences}>
        <p className={classes.preferencesTitle}>
          <Text
            data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
            className={classes.commonTitleStyles}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {titleText}
          </Text>
        </p>
        {bookingPreferences.map((bookingPreference: BookingPreference) => {
          if (bookingPreference.options.length > 1) {
            return getDropdownComponent(bookingPreference);
          }
          return null;
        })}
        {isClearButtonVisible ? (
          <p className={classes.clearPreferences}>
            <TextButton
              data-hook={BookingDetailsDataHooks.CLEAR_BUTTON}
              className={classes.commonLinkStyles}
              onClick={() => clearDropdowns()}
            >
              <span className={classes.textWithEllipsis}>{clearText}</span>
            </TextButton>
          </p>
        ) : null}
      </div>
    </div>
  );
};
