import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { DatePickerProps } from '../constants';

const WeeklyDatePicker = loadable(
  () => import(/* webpackChunkName: "WeeklyDatePicker" */ './WeeklyDatePicker'),
);

export const loadWeeklyDatePicker = () => WeeklyDatePicker.load();

export const LazyWeeklyDatePicker: React.FC<DatePickerProps> = (props) => (
  <div>
    <WeeklyDatePicker {...props} />
  </div>
);
