import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import BookingDetails from '../BookingDetails/BookingDetails';
import { SidebarViewModel } from '../../ViewModel/sidebarViewModel/sidebarViewModel';
import { FlowElements, useFlow, useStateFlow } from '../../Hooks/useFlow';
import Login from '../Login/Login';
import { classes } from './Sidebar.st.css';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface SidebarProps {
  viewModel: SidebarViewModel;
}

function isSidebarBelowBody(sidebar: HTMLDivElement | undefined) {
  const body = sidebar?.previousSibling as HTMLElement;
  return sidebar?.offsetLeft === body?.offsetLeft;
}

const Sidebar: React.FC<SidebarProps> = ({
  viewModel: { sidebarTitle, bookingDetailsViewModel, loginViewModel },
}) => {
  const [sidebar, setSidebar] = useStateFlow(FlowElements.SIDEBAR);
  const shouldBeCollapsed = isSidebarBelowBody(sidebar);
  const { experiments } = useExperiments();
  const isCollapseServiceDetailsEnabled = experiments.enabled(
    'specs.bookings.calendar.collapseServiceDetails',
  );

  return (
    <div
      ref={(ref) => setSidebar(ref!)}
      data-hook="sidebar"
      className={classes.root}
    >
      {!isCollapseServiceDetailsEnabled && (
        <SectionHeader title={sidebarTitle} />
      )}
      <BookingDetails
        viewModel={bookingDetailsViewModel}
        title={sidebarTitle}
        isCollapsed={shouldBeCollapsed}
      />
      <Login viewModel={loginViewModel} />
    </div>
  );
};

export default Sidebar;
